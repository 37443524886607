import React from "react";
import "../styles/Projects.css";
import { ProjectCard } from "../Globals/Imports";

const Projects = () => {
  return (
    <>
      <div className="projects" id="projects">
        <h1>projects</h1>
        <p>I'm proficient in React. So, if you have any project requirement, do contact me via. contact form below. Now, explore all my projects</p>
        <ProjectCard />
      </div>
    </>
  );
};

export default Projects;
